<template>
  <div :id="key + '_pieChart'" :style="{ width, height }" />
</template>

<script>
import * as echarts from "echarts";
import { EchartsJfCascadingTheme } from "@/assets/scripts/echarts-themes";

echarts.registerTheme("echarts-jf-theme", EchartsJfCascadingTheme);

export default {
  name: "PieChart",
  props: {
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "100%"
    },
    radius: {
      type: String,
      default: "85%"
    },
    center: {
      type: Array,
      default: () => ["50%", "50%"]
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      key: Math.floor(Math.random() * 10000)
    };
  },
  mounted() {
    let pieChart = echarts.init(
      document.getElementById(this.key + "_pieChart"),
      "echarts-jf-theme"
    );

    // Draw the chart
    pieChart.setOption({
      tooltip: {
        trigger: "item"
      },
      /*legend: {
        orient: 'vertical',
        left: 16
      },*/
      series: [
        {
          type: "pie",
          radius: this.radius,
          center: this.center,
          data: this.data
        }
      ]
    });

    window.addEventListener("resize", function() {
      pieChart.resize();
    });
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
