<template>
  <div :class="'col-' + width" style="min-width: 390px;">
    <q-card class="widget-container">
      <q-card-section class="flex justify-between items-center">
        <h6>{{ header }}</h6>
        <slot name="header" />
      </q-card-section>

      <Loader v-if="loading" />
      <slot v-else />

      <q-card-section
        v-if="showNavigationBar"
        class="flex justify-end items-center"
      >
        <a @click="navigationAction" class="flex items-center">
          <small>{{ navigationLabel }}</small>
          <q-icon size="1.2em" name="sym_r_chevron_right" color="primary" />
        </a>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader";

export default {
  name: "WidgetContainer",
  components: { Loader },
  props: {
    header: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: 4
    },
    loading: {
      type: Boolean,
      default: false
    },
    showNavigationBar: {
      type: Boolean,
      default: false
    },
    navigationLabel: {
      type: String,
      default: "View More"
    },
    navigationAction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
