<template>
  <WidgetContainer
    width="4"
    header="Visitors Breakdown"
    show-navigation-bar
    navigation-label="Go to Reporting"
    :navigation-action="() => $router.push({ name: 'AdvertiserReporting' })"
  >
    <template v-slot:header>
      <DateRangeSelector
        presets-only
        @update="dates => updateDateRange(dates)"
      />
    </template>
    <template v-slot>
      <Loader v-if="loading" />
      <div v-else>
        <!--<p class="--subtext q-ml-md">The number of clicks per visitor.</p>-->
        <div v-if="showNoDataAvailable">
          <NoDataAvailable class="q-my-xl" />
        </div>
        <PieChart v-else :data="chartData" class="q-mt-md" />
      </div>
    </template>
  </WidgetContainer>
</template>

<script>
import WidgetContainer from "@/components/UI/Widgets/WidgetContainer";
import DateRangeSelector from "@/components/UI/DateRangeSelector";
import PieChart from "@/components/UI/PieChart";
import axios from "axios";
import Loader from "@/components/UI/Loader";
import NoDataAvailable from "@/components/UI/NoDataAvailable";

export default {
  name: "AdvertiserVisitorsBreakdownWidget",
  components: {
    NoDataAvailable,
    Loader,
    PieChart,
    DateRangeSelector,
    WidgetContainer
  },
  props: {},
  data() {
    return {
      loading: false,
      startDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      chartData: [],
      showNoDataAvailable: false
    };
  },
  watch: {},
  mounted() {
    this.populateWidget();
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }

      this.populateWidget();
    },
    populateWidget() {
      this.loading = true;
      this.showNoDataAvailable = false;

      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/advertiser/reporting/visitors-breakdown",
          {
            start_date: this.startDate + " 00:00:00",
            end_date: this.endDate + " 23:59:59"
          }
        )
        .then(resp => {
          let visitorsBreakdown = resp.data.data[0];

          if (!visitorsBreakdown.click_visitors) {
            this.showNoDataAvailable = true;
            this.chartData = [];
          } else {
            this.chartData = [
              { value: visitorsBreakdown["click_visitors"], name: "1x Click" },
              {
                value: visitorsBreakdown["two_click_visitors"],
                name: "2x Clicks"
              },
              {
                value: visitorsBreakdown["three_click_visitors"],
                name: "3x Clicks"
              },
              {
                value: visitorsBreakdown["four_plus_click_visitors"],
                name: "4x+ Clicks"
              }
            ];
          }

          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
